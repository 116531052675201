import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { isValidEmail } from '../userService';

interface EmailFormProps {
  onSubmit: (email: string, token: string) => Promise<void>;
}

const EmailForm: React.FC<EmailFormProps> = ({ onSubmit }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    if (!isValidEmail(email)) {
      setMessage('Please enter a valid email address.');
      setIsLoading(false);
      return;
    }

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not available');
      setMessage('reCAPTCHA not available. Please try again later.');
      setIsLoading(false);
      return;
    }

    try {
      const token = await executeRecaptcha('email_subscription');
      await onSubmit(email, token);
      setEmail('');
      setMessage('Thank you for your interest! You\'re on the waitlist.');
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      if (error instanceof Error) {
        setMessage(`Error: ${error.message}`);
      } else {
        setMessage('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-8 md:py-16">
      <div className="max-w-xl mx-auto">
        <p className="text-custom-black font-bold text-base sm:text-lg mb-6 text-center">
          Get priority access to Sizzle's crypto portfolio management suite.
        </p>
        <form onSubmit={handleSubmit} className="mb-4">
          <div className="flex flex-col sm:flex-row items-center mb-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="flex-grow w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2 p-2 border border-gray-300 rounded text-custom-black"
              required
            />
            <button 
              type="submit" 
              className={`w-full sm:w-auto bg-[#F05A28] hover:bg-custom-black text-white font-bold py-2 px-4 rounded transition-colors duration-300 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isLoading}
            >
              {isLoading ? 'Submitting...' : 'Join the Waitlist'}
            </button>
          </div>
          <p className="text-xs text-gray-500 text-center">
            This site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy" className="text-blue-500 hover:underline">Privacy Policy</a> and{' '}
            <a href="https://policies.google.com/terms" className="text-blue-500 hover:underline">Terms of Service</a> apply.
          </p>
        </form>
        {message && (
          <p className={`text-center mt-4 ${message.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default EmailForm;